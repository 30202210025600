import React, { useState } from 'react'
import "./Home.css"
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import ChatIcon from '@mui/icons-material/Chat';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { translateApi } from '../../api/translate';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';








export default function Home() {

    const [open, setOpen] = React.useState(false);
    const [text, settext] = useState("");
    const [target, settarget] = useState("");
    const [translated_text, settranslated_text] = useState("");
    const [audioData, setAudioData] = useState(null);

    const handleSubmit  = async (e)=>{
        e.preventDefault()
        const data = {
            text:text,
            target:target
        }
        
        try{
            setOpen(true)
            const response = await translateApi(data)
            console.log(response)
            settranslated_text(response.data.translation.translations[0].translatedText);
            setAudioData(response.data.audioFile);
            setOpen(false)
            
        }
        catch(err){
            console.log(err)
        }
        
        settarget("");
    }

    
    
    
    
    
    
    

    return (
    <>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    <div className='admin-login-body'>

    <Grid container spacing={2} style={{height:'100%'}} alignContent={'center'} justifyContent={'center'}>
        <Grid item xs={11} sm={8} md={7} lg={6} xl={5}>

                <Card sx={{}} className='admin-card'>
                <CardContent>
                    <Typography gutterBottom variant="h4" component="div" className='admin-card-title'>
                    TEXT TO SPEECH
                    </Typography>
                </CardContent>




                        <form  onSubmit={handleSubmit}>


                {/* Input */}
                    <Grid container spacing={4} justifyContent={'center'} alignContent={'center'}>


                        <Grid item  xs={11} sm={9} md={8} lg={9} xl={9} justifyItems={'center'}>

                                <FormControl fullWidth> 
                                     <Input
                                     disableUnderline={true}
                                     type='text'
                                     placeholder="Write Your English Text Here"
                                     required={true}
                                     value={text}
                                     className='admin-input'
                                     onChange={(e)=> settext(e.target.value)}
                                     startAdornment={
                                    <InputAdornment position="start" sx={{marginLeft:'0.5rem'}}>
                                        <ChatIcon/>
                                    </InputAdornment>
                                }
                                />
                            </FormControl>

                        </Grid>


                        <Grid item  xs={11} sm={9} md={8} lg={9} xl={9} justifyItems={'center'}>
                            
                                <FormControl fullWidth> 
                                <Select
                                value={target}
                                onChange={(e)=> settarget(e.target.value)}
                                disableUnderline={true}
                                className='admin-input'
                                required
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='hi'>Hindi</MenuItem>
                                <MenuItem value='fr'>French</MenuItem>
                                <MenuItem value='de'>German</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>



            
            

                        <Grid item xs={8} sm={7} md={6} lg={6} xl={5}>
                         <Button size="medium" type='submit' className='btn-login-card' fullWidth>TRANSLATE</Button>
                        </Grid>




                        <Grid item  xs={11} sm={9} md={8} lg={9} xl={9} justifyItems={'center'}>
                        
                        <div className='admin-input-result' style={{display:'flex', justifyContent:'center' , alignItems:'center'}} >
                                <div style={{display:'flex', justifyContent:'center' , alignItems:'center'}}>
                                    {translated_text}
                                </div>
                        </div>

                        </Grid>


                        
                        <Grid item  xs={11} sm={9} md={8} lg={9} xl={9} justifyItems={'center'}>

                        {audioData && (
                                <div style={{display:'flex', justifyContent:'center' , alignItems:'center'}}>
                                <audio src={audioData} type="audio/mpeg" style={{display:'flex', justifyContent:'center' , alignItems:'center'}} controls fullWidth />
                                </div>
                               
                                

                            )}  

                        </Grid>





                        </Grid>
                
                        </form>
            



                <CardActions>

                </CardActions>
            </Card>




        </Grid>
      </Grid>
    </div>
        
    
   </>
  )
}

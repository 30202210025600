import './App.css';
import Home from './components/Home/Home';

function App() {
  return (
   <>
    <Home/>  
   </>
  );
}

export default App;

import axios from 'axios';

export const translateApi = async (inputData) => {
  
  try {
    const options = {
        method: 'POST',
        url: 'https://assignment.rohankm.online/convert-to-speech',
        headers: {
          'content-type': 'application/json',
        },
        data:inputData
    };


    const response = await axios(options);
    return response


  } catch (error) {
    console.log(error)
  }
};